<script setup lang="ts">
import { useSurveyResponseStore } from '~~/stores/surveyResponse.store'
import { useProductStore } from '~~/stores/product.store'
import { useCompanyStore } from '~~/stores/company.store'

const { hasSurveyScore } = storeToRefs(useSurveyResponseStore())
const { surveyType } = useSurvey()

const { product } = storeToRefs(useProductStore())

useLazyAsyncData(
  () => Promise.all([useProductStore().fetch(), useCompanyStore().fetch()]),
  {
    server: false,
  }
)

const companyLink = computed(() =>
  hasSurveyScore.value ? '/dashboard/company/result' : '/dashboard/company/'
)

const productLink = computed(() =>
  product.value?.id
    ? `/dashboard/product/${product.value?.id}/result`
    : '/dashboard/product/'
)
</script>
<template lang="pug">
nav(class="hidden lg:flex btn-group gap-12 lg:gap-24 xl:gap-48" role="navigation")
  NuxtLink(
    to="/dashboard/"
    class="hidden lg:block py-3 text-sm font-medium text-brand-900 hover:text-brand whitespace-nowrap"
  ) Dashboard
  NuxtLink(
    :to="companyLink"
    class="block py-3 text-sm font-medium text-brand-900 hover:text-brand whitespace-nowrap"
    :class="{ active: surveyType === 'company' }"
  ) Company ID
  NuxtLink(
    :to="productLink"
    class="block py-3 text-sm font-medium text-brand-900 hover:text-brand whitespace-nowrap"
    :class="{ active: surveyType === 'product' }"
  ) Product ID
</template>

<style lang="postcss" scoped>
nav a {
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px;

  &.active,
  &.router-link-exact-active {
    @apply text-brand;
    background-image: linear-gradient(
      90deg,
      theme(colors.brand.DEFAULT),
      theme(colors.brand.DEFAULT)
    );
  }
}
</style>
