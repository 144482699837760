<script setup lang="ts">
import { useAppStore } from '~~/stores/app.store'
import { useCompanyStore } from '~~/stores/company.store'
import { useProfileStore } from '~~/stores/profile.store'

const { company } = storeToRefs(useCompanyStore())
const { isProUser } = storeToRefs(useProfileStore())
const { isPrimaryNavHidden } = storeToRefs(useAppStore())
</script>
<template lang="pug">
header(
  v-if="company"
  class="fixed top-0 w-full z-50 bg-gray-100 items-center px-18 md:px-36 pt-21 pb-9 md:pt-24 md:pb-12 transition-transform ease-in-out duration-500"
  :class="{ '-translate-y-full duration-300': isPrimaryNavHidden }"
)
  div(class="pt-safe flex justify-between gap-24 lg:gap-36 xl:gap-48")
    div(class="flex gap-18 xl:gap-27 items-center")
      div(class="brand w-[123px] h-30 xl:w-[136px] xl:h-33")
        NuxtLink(to="/dashboard")
          img(
            src="/img/openesg.svg"
            alt="OpenESG"
            width="160"
            height="36"
            class="object-fit"
          )
      div(
        v-if="company?.name"
        class="flex h-42 items-center sm:border-l-2 sm:border-gray-300 sm:pl-18 font-bold text-md text-gray-900"
      )
        NuxtLink(to="/dashboard" class="flex items-center gap-12")
          span(
            class="hidden sm:block max-w-[33vw] lg:max-w-[17.5vw] xxl:max-w-[33vw] whitespace-nowrap overflow-hidden text-ellipsis"
          ) {{ company.name }}
          UiProAccountBadge(v-if="isProUser")

    HeaderPrivateNav

    div(class="justify-end btn-group gap-6 md:gap-18 xl:gap-36")
      UserAccountButton

      NuxtLink(
        v-if="!isProUser"
        class="btn btn-sm md:btn-base btn-error"
        to="/dashboard/subscribe"
      )
        MaterialSymbol task_alt
        span Get Verified
</template>
