<script setup lang="ts">
import { useProfileStore } from '~~/stores/profile.store'
const { profile } = storeToRefs(useProfileStore())
const { auth } = useSupabaseClient()

const userName = computed(() => profile?.value?.name)

const userInitials = computed(() => {
  if (!profile?.value?.name) return ''

  const parts = profile?.value?.name.split(' ')
  let initials = ''

  // get first two letters of name
  for (let i = 0; i < parts.length && i < 2; i++) {
    initials += parts[i].charAt(0)
  }

  return initials
})
</script>

<template lang="pug">
nav(
  v-if="profile"
  role="navigation"
  aria-label="My Account"
  class="dropdown dropdown-end text-sm text-medium"
)
  label(
    tabindex="0"
    class="flex md:gap-3 xl:gap-12 h-42 md:h-48 items-center cursor-pointer p-0"
    :aria-label="userName || 'My Account'"
  )
    div(class="avatar placeholder")
      div(class="bg-gray-900 text-gray-100 rounded-full w-30 text-xs font-bold")
        span(class="uppercase" v-if="userInitials") {{ userInitials }}
        MaterialSymbol(v-if="!userInitials" class="ml-1 mb-2") person

    span(class="flex gap-3 items-center text-gray-600")
      span(class="hidden xl:block") {{ userName || 'My Account' }}
      MaterialSymbol(:size="20") expand_more

  ul(tabindex="0" class="dropdown-content menu shadow bg-gray-100 w-240")
    li(class="border-b border-gray-200")
      NuxtLink(to="/dashboard/profile")
        MaterialSymbol(:size="18") account_circle
        span My Account
    li(class="border-b border-gray-200")
      NuxtLink(to="/dashboard/company/manage")
        MaterialSymbol(:size="18") settings
        span Manage Company
    li
      button(@click="() => auth.signOut()")
        MaterialSymbol(:size="18") logout
        span Log out
</template>
